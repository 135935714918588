import React from "react";
import * as styles from "./styles.module.scss";
import cn from "classnames";
import { Background, FeatureBrowser, SliceConfig } from "~components";
import { graphql, useStaticQuery } from "gatsby";

interface IQueryData {
  allSanityPage: {
    nodes: {
      disabled: boolean;
      slug: {
        current: string;
      };
      title: string;
    }[];
  };
}

const Purchase = () => {
  const {
    allSanityPage: { nodes: pageNodes }
  }: IQueryData = useStaticQuery(query);

  const unlockedFeatures = pageNodes.filter((page) => !page.disabled);

  return (
    <>
      <div className={styles.atf}>
        <Background />

        <div className={styles.content}>
          <SliceConfig>
            <h1 className={cn("d1")}>Purchase</h1>
          </SliceConfig>
        </div>
      </div>

      <SliceConfig>
        <p className={cn("h1", styles.title)}>
          You have the following features unlocked:
        </p>

        <div className={styles.featureList}>
          {unlockedFeatures.map((page) => (
            <p className={cn("b1", styles.feature)}>{page.title}</p>
          ))}
        </div>

        <p className={cn("h1", styles.title)}>
          The following features are available for purchase:
        </p>

        <FeatureBrowser />
      </SliceConfig>
    </>
  );
};

export default Purchase;

const query = graphql`
  query {
    allSanityPage {
      nodes {
        disabled
        title
        slug {
          current
        }
      }
    }
  }
`;
